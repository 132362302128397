import React, { PureComponent, createRef } from 'react';
import { select, geoPath, geoAlbersUsa } from 'd3';
import { feature } from 'topojson';
import { filter, sortBy } from 'lodash';
import { img } from '../../lib/brand';
import styles from './styles.module.less';
import mapData from '../../../static/mapUS.json';

var pattern = img('pattern.jpg');

class BranchMap extends PureComponent {
   constructor(props) {
      super(props);
      this.state = {
         width: 960,
         height: 600,
         scale: 0.5,
         mapOffset: 0,
         isMapDrawn: false,
         isBranchesDrawn: false,
         branches: [],
         hasMapErrored: false,
         shouldAddPattern: false
      };
      this.map = createRef();
   }

   async componentDidMount() {
      window.addEventListener('scroll', this.listenToScroll);
      window.addEventListener('resize', this.listenToResize);
      const scale = this.returnScale();
      try {
         // fetch map data and data from lunr
         if (!window || !window.__LUNR__) {
            throw Error('not loaded');
         }
         const { en: { store = {} } = {} } = await window.__LUNR__.__loaded;
         const branches = sortBy(filter(store, ['type', 'branch']), 'lon');
         this.setState(
            {
               mapOffset: this.map.current.getBoundingClientRect().top,
               isMapDrawn: true,
               branches,
               scale
            },
            () => this.createSvg()
         );
      } catch (error) {
         this.setState({ hasMapErrored: true });
      }
   }

   componentWillUnmount() {
      window.removeEventListener('scroll', this.listenToScroll);
      window.removeEventListener('resize', this.listenToResize);
   }

   returnScale = () => {
      const width = window.innerWidth;
      if (width <= 500) {
         return 0.3;
      }
      if (width <= 768) {
         return 0.4;
      }
      if (width <= 1024) {
         return 0.5;
      }
      return 0.65;
   };

   listenToResize = () => {
      const { isBranchesDrawn, isMapDrawn, hasMapErrored } = this.state;
      if (hasMapErrored) {
         return;
      }
      const mapOffset = this.map.current.getBoundingClientRect().top;
      const scale = this.returnScale();
      this.setState({ scale, mapOffset }, () => {
         this.createSvg(isMapDrawn ? 'shouldRedraw' : null);
         if (isBranchesDrawn) {
            setTimeout(this.addBranches, 1000);
         }
      });
   };

   listenToScroll = () => {
      const { isMapDrawn, isBranchesDrawn, mapOffset, hasMapErrored } = this.state;
      const isInView = window.pageYOffset + 700 >= mapOffset;
      if (!isMapDrawn || isBranchesDrawn || !isInView || hasMapErrored) {
         return;
      }
      this.addBranches();
      this.setState({
         isBranchesDrawn: true
      });
   };

   createSvg = shouldRedraw => {
      const { scale, shouldAddPattern, height, width } = this.state;
      // create svg map
      const svg = select(this.map.current);
      const path = geoPath();

      if (shouldRedraw) {
         // get rid old map and dots first
         svg.selectAll('*').remove();
      }

      if (shouldAddPattern) {
         // add pattern here
         svg.append('defs')
            .append('pattern')
            .attr('id', 'statesPattern')
            .attr('patternUnits', 'userSpaceOnUse')
            .attr('width', width * scale)
            .attr('height', height * scale)
            .append('image')
            .attr('href', pattern)
            .attr('width', width * scale)
            .attr('height', height * scale)
            .attr('x', 0)
            .attr('y', 0);
      }
      // add map
      svg.append('g')
         .style('fill', `${shouldAddPattern ? 'url(#statesPattern)' : '#131a2b'}`)
         .selectAll('path')
         .data(feature(mapData, mapData.objects.nation).features)
         .enter()
         .append('path')
         .attr('d', path)
         .attr('class', styles.mapPath)
         .attr('transform', `scale(${scale})`);
   };

   addBranches = () => {
      const { branches, scale } = this.state;
      const svg = select(this.map.current);
      const projection = geoAlbersUsa().scale(1280).translate([480, 300]);
      let i = 0;
      let arr = [];
      const drawDot = () => {
         arr.push(branches[i]);
         svg.selectAll('circle')
            .data(arr)
            .enter()
            .append('circle')
            .attr('cx', arr => projection([arr.lon, arr.lat])[0])
            .attr('cy', arr => projection([arr.lon, arr.lat])[1])
            .attr('r', 6)
            .attr('class', styles.branches)
            .attr('transform', `scale(${scale})`);
         i++;
         if (i === branches.length) {
            clearInterval(intervalDots);
         }
      };
      const intervalDots = setInterval(drawDot, 12);
   };

   render() {
      const { hasMapErrored, height, width, scale } = this.state;
      return (
         <>
            {!hasMapErrored && (
               <div className={styles.container}>
                  <svg ref={this.map} height={height * scale} width={width * scale} className={styles.mapPath} />
               </div>
            )}
         </>
      );
   }
}

export default BranchMap;
